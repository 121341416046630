<script setup lang="ts">
import { ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { mdiAlertCircleOutline, mdiDeleteForever } from '@mdi/js'
import DialogPrompt from './DialogPrompt.vue'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import ErrorButton from './ErrorButton.vue'
import DefaultButton from './DefaultButton.vue'

interface Props {
  open?: boolean
  hasChanges?: boolean
}

interface Emits {
  (e: 'close'): void
}

defineOptions({ inheritAttrs: false })
const props = defineProps<Props>()
const emits = defineEmits<Emits>()
const isConfirm = ref<boolean>(false)

const onChanges = (): void => {
  if (!props.hasChanges) {
    emits('close')
  } else {
    isConfirm.value = true
  }
}

const onClose = (): void => {
  isConfirm.value = false
  emits('close')
}
</script>

<template>
  <div
    class="fixed z-40 left-0 top-0 bg-gray-900 transform transition-opacity ease-in-out duration-300 h-full w-full"
    :class="open ? 'opacity-60' : 'opacity-0 collapse'"
  ></div>
  <div
    class="fixed z-40 left-0 top-0 flex transform transition-all ease-in-out duration-300 justify-center items-center w-full h-full"
    :class="open ? 'translate-y-0 opacity-100 overflow-y-auto' : 'overflow-y-initial translate-y-8 opacity-0 collapse'"
  >
    <div
      v-if="open"
      class="w-full max-h-lvh max-w-2xl bg-white shadow-lg"
      :class="{ 'animate-shake': isConfirm }"
      v-on-click-outside="() => onChanges()"
      v-bind="$attrs"
    >
      <slot></slot>
    </div>
  </div>
  <DialogPrompt v-model:open="isConfirm" :title="$t('message.discardChange', 2)">
    <div class="flex justify-center">
      <SvgIcon :path="mdiAlertCircleOutline" class="fill-gray-700 h-24 w-24" />
    </div>
    <div class="px-4 text-gray-700">
      <div class="font-medium text-md pt-4 pb-1 text-center">{{ $t('message.unsavedConfirm') }}</div>
      <div class="font-light text-sm pb-4 text-center">{{ $t('message.unsavedWarning') }}</div>
    </div>
    <div class="flex items-center justify-between p-4">
      <ErrorButton :text="$t('common.discard')" :icon="mdiDeleteForever" @click="onClose()" />
      <DefaultButton :text="$t('common.cancel')" @click="isConfirm = false" />
    </div>
  </DialogPrompt>
</template>
