<script setup lang="ts">
import { computed } from 'vue'
import { mdiLoading } from '@mdi/js'
import SvgIcon from './SvgIcon.vue'

interface Props {
  title?: string
  text?: string
  icon?: string
  loading?: boolean
  disabled?: boolean
}

interface Emits {
  (e: 'click'): void
}

defineProps<Props>()
const emits = defineEmits<Emits>()
const errors = defineModel<boolean[]>('errors')
const isInvalid = computed(() => errors.value?.find((error) => error))

const validate = (): void => {
  if (errors.value) {
    if (!isInvalid.value) {
      emits('click')
    }
  } else {
    emits('click')
  }
}
</script>

<template>
  <button
    type="button"
    class="group inline-flex items-center transition-all duration-200 border border-error text-white bg-error p-2 enabled:cursor-pointer enabled:hover:shadow-inner disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-400"
    :title="title || text"
    :class="[
      isInvalid
        ? 'enabled:bg-error enabled:border-error enabled:hover:bg-error/80 enabled:hover:border-error active:animate-wiggle'
        : 'enabled:hover:bg-error/80',
    ]"
    @click="validate()"
    :disabled="loading || disabled"
    v-bind="$attrs"
  >
    <SvgIcon v-if="loading" :path="mdiLoading" class="animate-spin h-5 w-5" />
    <SvgIcon v-else :path="icon" class="fill-white h-5 w-5 group-disabled:fill-gray-400" />
    <span v-if="text" class="ms-1.5 me-2 text-sm text-nowrap">{{ text }}</span>
  </button>
</template>
