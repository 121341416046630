import { ref } from 'vue'
import { defineStore } from 'pinia'
import type {
  ClientViewDto,
  ClientCarrierDto,
  ClientCountryDto,
  ClientZipZoneDto,
  ClientDimensionDto,
  ClientServiceGroupDto,
} from '@/api/models/types'
import { useClientApi } from '@/api/services/client'

export const useClientStore = defineStore('client', () => {
  const api = useClientApi()
  const clientKey = ref<string>('')

  const getClientViews = async (clientKey: string): Promise<ClientViewDto[]> => {
    const { data, error } = await api.getClientViews(clientKey)
    if (!error && data) {
      return data
    }
    return []
  }

  const getClientCarriers = async (clientKey: string): Promise<ClientCarrierDto[]> => {
    const { data, error } = await api.getClientCarriers(clientKey)
    if (!error && data) {
      return data
    }
    return []
  }

  const getClientCountries = async (clientKey: string): Promise<ClientCountryDto[]> => {
    const { data, error } = await api.getClientCountries(clientKey)
    if (!error && data) {
      return data
    }
    return []
  }

  const getClientZipZones = async (clientKey: string): Promise<ClientZipZoneDto[]> => {
    const { data, error } = await api.getClientZipZones(clientKey)
    if (!error && data) {
      return data
    }
    return []
  }

  const getClientDimension = async (clientKey: string, id: number): Promise<ClientDimensionDto[]> => {
    const { data, error } = await api.getClientDimension(clientKey, id)
    if (!error && data) {
      return data
    }
    return []
  }

  const getClientServiceGroups = async (clientKey: string): Promise<ClientServiceGroupDto[]> => {
    const { data, error } = await api.getClientServiceGroups(clientKey)
    if (!error && data) {
      return data
    }
    return []
  }

  return {
    clientKey,
    getClientViews,
    getClientCarriers,
    getClientCountries,
    getClientZipZones,
    getClientDimension,
    getClientServiceGroups,
  }
})
