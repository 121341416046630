import { useApi } from '..'
import type {
  ClientCarrierDto,
  ClientCountryDto,
  ClientDimensionDto,
  ClientErrorData,
  ClientServiceGroupDto,
  ClientViewDto,
  ClientZipZoneDto,
} from '../models/types'

export const useClientApi = () => {
  const api = useApi()
  const mainUrl = import.meta.env.VITE_MAIN_BASE_URL

  const getClientViews = async (
    clientKey: string,
  ): Promise<{ data: ClientViewDto[] | null; error: ClientErrorData | null }> =>
    await api.request.get<ClientViewDto[]>(mainUrl, `client/${clientKey}/views`)

  const getClientCarriers = async (
    clientKey: string,
  ): Promise<{ data: ClientCarrierDto[] | null; error: ClientErrorData | null }> =>
    await api.request.get<ClientCarrierDto[]>(mainUrl, `client/${clientKey}/carriers`)

  const getClientCountries = async (
    clientKey: string,
  ): Promise<{ data: ClientCountryDto[] | null; error: ClientErrorData | null }> =>
    await api.request.get<ClientCountryDto[]>(mainUrl, `client/${clientKey}/countries`)

  const getClientZipZones = async (
    clientKey: string,
  ): Promise<{ data: ClientZipZoneDto[] | null; error: ClientErrorData | null }> =>
    await api.request.get<ClientZipZoneDto[]>(mainUrl, `client/${clientKey}/zip-zones`)

  const getClientDimension = async (
    clientKey: string,
    id: number,
  ): Promise<{ data: ClientDimensionDto[] | null; error: ClientErrorData | null }> =>
    await api.request.get<ClientDimensionDto[]>(mainUrl, `client/${clientKey}/dimension?number=${id}`)

  const getClientServiceGroups = async (
    clientKey: string,
  ): Promise<{ data: ClientServiceGroupDto[] | null; error: ClientErrorData | null }> =>
    await api.request.get<ClientServiceGroupDto[]>(mainUrl, `client/${clientKey}/service-groups`)

  return {
    getClientViews,
    getClientCarriers,
    getClientCountries,
    getClientZipZones,
    getClientDimension,
    getClientServiceGroups,
  }
}
