<script setup lang="ts">
import UnstyledButton from './UnstyledButton.vue'
import SvgIcon from './SvgIcon.vue'

interface Props {
  title: string
  icon: string
  badge?: boolean
}

defineProps<Props>()
const model = defineModel<boolean>({ required: true })
</script>

<template>
  <UnstyledButton
    v-if="icon"
    :title="title"
    class="flex items-center rounded-full p-1"
    :class="model ? 'bg-white/20' : 'hover:bg-white/10'"
    @click="model = !model"
  >
    <SvgIcon :path="icon" class="fill-primary-light h-6 w-6" />
    <div v-if="badge" class="absolute right-1.5 top-2">
      <span class="relative flex size-2">
        <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-error opacity-75"></span>
        <span class="relative inline-flex size-2 rounded-full bg-error"></span>
      </span>
    </div>
  </UnstyledButton>
</template>
