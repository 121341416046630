<script setup lang="ts">
import { mdiMagnify, mdiCloseCircle } from '@mdi/js'
import { vOnClickOutside } from '@vueuse/components'
import SvgIcon from '@/shared/components/SvgIcon.vue'
import UnstyledButton from '@/shared/components/UnstyledButton.vue'

interface Emits {
  (e: 'change'): void
  (e: 'clear'): void
  (e: 'open'): void
}

defineOptions({ inheritAttrs: false })
const emits = defineEmits<Emits>()
const model = defineModel<string>({ required: true })
const active = defineModel<boolean>('active', { required: true })
const focus = defineModel<boolean>('focus', { required: true })

const setFocus = (state: boolean) => {
  focus.value = state
}

const open = () => {
  setFocus(true)
  emits('open')
}

const change = () => {
  setFocus(true)
  emits('change')
}

const close = () => {
  if (model.value.length === 0) {
    setFocus(false)
  }
}

const clear = () => {
  model.value = ''
  emits('clear')
}
</script>

<template>
  <div class="relative" :class="{ 'shadow-md': active }" v-on-click-outside="() => close()">
    <div class="absolute inset-y-0 flex items-center ms-2">
      <SvgIcon :path="mdiMagnify" class="h-5 w-5" :class="active ? 'fill-gray-700' : 'fill-primary-light'" />
    </div>
    <input
      :name="$t('common.search')"
      type="text"
      autocomplete="off"
      :placeholder="''"
      spellcheck="false"
      v-model="model"
      class="block w-full placeholder:text-primary-light border bg-primary-dark text-sm outline-none transition-all duration-200 ease-in-out pr-20 ps-8 px-2 py-1"
      :class="[{ 'mr-64': focus }, active ? 'mr-64 bg-white' : 'border-primary-light text-primary-light']"
      @keydown.enter="change()"
      @click="open()"
      v-bind="$attrs"
    />
    <label
      v-if="!focus && model.length === 0"
      :name="$t('common.search')"
      class="pointer-events-none text-sm absolute inset-y-0 start-8 flex items-center"
    >
      <div class="text-primary-light px-1">
        {{ $t('placeholder.search') }}
      </div>
    </label>
    <div class="absolute inset-y-0 end-2 flex items-center ms-4"></div>
    <div v-if="model && model.length > 0" class="absolute inset-y-0 end-2 flex items-center ms-4">
      <UnstyledButton :title="$t('common.clear')" @click="clear()">
        <SvgIcon :path="mdiCloseCircle" class="h-5 w-5" :class="active ? 'fill-gray-700' : 'fill-primary-light'" />
      </UnstyledButton>
    </div>
  </div>
</template>
